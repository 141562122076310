import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../layout/layout";

export const query = graphql`
    query($slug: String) {
        sanityHelp(url: { current: { eq: $slug } }) {
            title
            content {
                children {
                    text
                }
            }
            help_id
            image {
                asset {
                    gatsbyImageData
                }
            }
            url {
                current
            }
        }
    }
`;

const HelpTemplate = ({ data }) => {
    const help = data.sanityHelp;
    return (
        <>
            <Layout>
                <div className="help-banner pt-24 pb-36">
                    <div className="container w-full sm:w-5/6 md:w-2/3 mx-auto flex flex-wrap">
                        <span>{help.help_id}</span>
                        <h1 className="heading w-full py-4 mb-0">{help.title}</h1>
                    </div>
                </div>
                <main
                    id="main-content"
                    className="container w-full sm:w-5/6 md:w-2/3 mx-auto flex flex-wrap -m-28 p-6"
                >
                    <div className="container mx-auto w-full sm:w-2/3">
                        {help.content.map((block) =>
                            block.children.map((content, i) =>
                                content.text ? <p key={i}>{content.text}</p> : ""
                            )
                        )}
                    </div>
                    <div className="container mx-auto w-full sm:w-1/3">
                        {help.image ? (
                            <GatsbyImage
                                className="help-image"
                                image={help.image.asset.gatsbyImageData}
                                alt={help.title}
                            />
                        ) : null}
                    </div>
                </main>
            </Layout>
        </>
    );
};

export default HelpTemplate;
